function fetchCurrentViewCount(){
    var videoId = $(".row-id td")[0].textContent;
    $.ajax({
        url: '/api/video/live_streaming/'+videoId+'/current_view_count',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
            $(".row-current_view_count td").eq(0).html(response.data);
        }
    });
}

function fetchTotalViewCount(){
    var videoId = $(".row-id td")[0].textContent;
    $.ajax({
        url: '/api/video/live_streaming/'+videoId+'/total_view_count',
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        success: function(response) {
            $(".row-total_view_count td").eq(0).html(response.data);
        }
    });
}

$(document).ready(function(){
    if ($(".row-total_view_count td").length) {
        // update every 5 minutes
        setInterval(fetchTotalViewCount, 300000);
    
        // update every 15 seconds
        setInterval(fetchCurrentViewCount, 15000);
    }
});
