// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"
require('activeadmin_quill_editor')

import "./active_admin/admin_channel"
import "./active_admin/generate_youtube"
import "./active_admin/youtube_view_count"
import "./active_admin/hello"
import "../channels/comment_channel"