import consumer from "./consumer"

const chatRoomChannel = (room) => consumer.subscriptions.create(
    {channel: "CommentChannel", room: room}, {
        connected() {
            // Called when the subscription is ready for use on the server
            console.log("Connected to the my chat room!");
        },

        disconnected() {
            // Called when the subscription has been terminated by the server
        },

        // cara manggil di .rb ruby
        // ActionCable.server.broadcast("chat_room_channel", data)
        received(data) {
            console.log(data)
            if (data.type == "new") {
                if (data.comment.is_admin) {
                    // user view
                    $("#messages").append(`<p class="received admin" id="comment-${data.record.id}" style="display: block"><span id="comment_name">${data.comment.name}</span>&nbsp;&nbsp;<span id="comment_body">${data.comment.body}</span></p>`)

                    // admin panel view
                    $("#admin_panel_messages").append(`<p class="received admin"><span class="admin_name">One Pride&nbsp;✓</span> ${data.comment.body}&nbsp;&nbsp;
                                                    <a id="pin_comment" data-video-id=${data.comment.video_id} data-comment-id=${data.record.id} data-comment-body="${data.comment.body}">Pin</a>&nbsp;
                                                    <a id="archive_comment" data-video-id=${data.comment.video_id} data-comment-id=${data.record.id}><span id="archive-${data.record.id}">Archive</span></a>
                                                </p>`)
                } else {
                    // user view
                    $("#messages").append(`<p class="received" id="comment-${data.record.id}" style="display: block"><span id="comment_name">${data.comment.name}</span>&nbsp;&nbsp;<span id="comment_body">${data.comment.body}</span></p>`)

                    // admin panel view
                    $("#admin_panel_messages").append(`<p class="received admin"><span class="user_name">${data.comment.name}</span> ${data.comment.body}&nbsp;&nbsp;
                                                    <a id="pin_comment" data-video-id=${data.comment.video_id} data-comment-id=${data.record.id} data-comment-body="${data.comment.body}">Pin</a>&nbsp;
                                                    <a id="archive_comment" data-video-id=${data.comment.video_id} data-comment-id=${data.record.id}><span id="archive-${data.record.id}">Archive</span></a>
                                                </p>`)
                }
            } else if (data.type == "pin") {
                // user view
                if (data.comment.is_admin) {
                    $("#pinned_comment p").addClass("admin")
                    $("#admin_panel_pinned_comment p").addClass("admin")
                } else {
                    $("#pinned_comment p").removeClass("admin")
                    $("#admin_panel_pinned_comment p").removeClass("admin")
                }

                $("#pinned_comment h2").show()
                $("#pinned_comment #pinned_comment_text").show()
                $("#pinned_comment #pinned_comment_text #comment_name").text("📌 " + data.comment.name);
                $("#pinned_comment #pinned_comment_text #comment_body").text(data.comment.body);

                // admin panel view
                $("#admin_panel_pinned_comment h2").show()
                $("#admin_panel_pinned_comment #pinned_comment_text").show()
                $("#admin_panel_pinned_comment #pinned_comment_text #comment_name").text("📌 " + data.comment.name);
                $("#admin_panel_pinned_comment #pinned_comment_text #comment_body").text(data.comment.body);
                $("#admin_panel_pinned_comment #unpin_comment").show();
            } else if (data.type == "unpin") {
                // user view
                $("#pinned_comment h2").hide()
                $("#pinned_comment #pinned_comment_text").hide()

                // admin panel view
                $("#admin_panel_pinned_comment h2").hide()
                $("#admin_panel_pinned_comment #pinned_comment_text").hide()
                $("#admin_panel_pinned_comment #unpin_comment").hide();
            } else if (data.type == "archive" || data.type == "unarchive") {
                if (data.type == "archive") {
                    // user view
                    $(`#messages #comment-${data.comment.id} #comment_body`).text("comment has been moderated");
                    $("#pinned_comment h2").hide()
                    $("#pinned_comment #pinned_comment_text").hide()

                    // admin panel view
                    if (data.pinned_comment_id) {
                        $("#admin_panel_pinned_comment h2").hide()
                        $("#admin_panel_pinned_comment #pinned_comment_text").hide()
                        $("#admin_panel_pinned_comment #unpin_comment").hide();
                    }

                    $(`#admin_panel_messages #archive_comment #archive-${data.comment.id}`).text("Unarchive");
                } else {
                    // user view
                    $(`#messages #comment-${data.comment.id} #comment_body`).text(data.comment.body);

                    // admin panel view
                    $(`#admin_panel_messages #archive_comment #archive-${data.comment.id}`).text("Archive");
                    $(`#admin_panel_messages #pin_comment #comment-${data.comment.id}`).show();
                }
            } else if (data.type == "update_video") {
                if (data.allow_comment) {
                    $("#comment_form #message").removeAttr('disabled');
                    $("#comment_form #message").attr("placeholder", "Send a message");
                } else {
                    $("#comment_form #message").attr('disabled', '');
                    $("#comment_form #message").attr("placeholder", "Chat has been disabled");
                }
            }
        },
    });

// we need to export this, so we can access speak method from another javascript

export default chatRoomChannel;
