$(document).ready(function() {
    var youtube_id_changed = false;
    var embed_url = 'https://www.youtube.com/embed/';
    var thumbnail_url = 'https://img.youtube.com/vi/';
    $("#live_streaming_video_youtube_id, #march_fight_night_video_youtube_id, #cms_workout_video_youtube_id, #advertisement_video_youtube_id").keyup(function() {
        youtube_id_changed = true;
        $('#preview_youtube').attr('src', embed_url+$(this).val());
        $('#thumbnail').attr('src', thumbnail_url+$(this).val()+'/maxresdefault.jpg');
        $('#thumbnail_src').attr('href', thumbnail_url+$(this).val()+'/maxresdefault.jpg');
        $('#live_streaming_video_youtube_thumbnail_url, #march_fight_night_video_youtube_thumbnail_url, #cms_workout_video_youtube_thumbnail_url').attr('value', thumbnail_url+$(this).val()+'/maxresdefault.jpg');

        // insert live streaming start time
        if ($('#live_streaming_video_live_time_schedule').length) {
            $.ajax({
                url: '/api/video/live_streaming/'+$(this).val()+'/live_time_schedule',
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                success: function(response) {
                    $("#live_streaming_video_live_time_schedule").val(response.data);
                }
            });
        }
    });
    if (youtube_id_changed == false) {
        var youtube_id = $("#live_streaming_video_youtube_id, #march_fight_night_video_youtube_id, #cms_workout_video_youtube_id, #advertisement_video_youtube_id").val();
        $('#preview_youtube').attr('src', embed_url+youtube_id);
        $('#thumbnail').attr('src', thumbnail_url+youtube_id+'/maxresdefault.jpg');
        $('#thumbnail_src').attr('href', thumbnail_url+youtube_id+'/maxresdefault.jpg');
        $('#live_streaming_video_youtube_thumbnail_url, #march_fight_night_video_youtube_thumbnail_url, #cms_workout_video_youtube_thumbnail_url').attr('value', thumbnail_url+youtube_id+'/maxresdefault.jpg');
    }
})
