import commentChannelFn from "../../channels/comment_channel";

$(document).ready(function() {
    console.log('We made it!')
    const elem = document.getElementById("room_name")
    if (elem) {
        // auto scroll down when received new message
        window.setInterval(function() {
            $('#admin_panel_messages')[0].scrollTop = $('#admin_panel_messages')[0].scrollHeight;
        }, 3000);

        const dataset = elem.dataset
        commentChannelFn(dataset.roomName)

        $("form#comment_form").on('submit', function (e) {
            e.preventDefault();
            let message = $('#message').val();
            var data = { comment: { video_id: dataset.roomName, body: message, is_admin: true } }
            $.ajax({
                url: '/api/comment/admin',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data:  JSON.stringify(data),
                success: function() {
                    if (message.length > 0) {
                        $('#message').val('')
                    }
                }
            });
        });

        $("#admin_panel_messages").on('click', '#pin_comment', function (e) {
            e.preventDefault();
            var video_id =  $(this).data("video-id");
            var comment_id =  $(this).data("comment-id");
            var data = { video_id: video_id, comment_id: comment_id }
            $.ajax({
                url: '/api/comment/pin_comment',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data:  JSON.stringify(data),
                success: function() {
                    // console.log(data)
                }
            });
        });

        $("#admin_panel_pinned_comment").on('click', '#unpin_comment', function (e) {
            e.preventDefault();
            var video_id =  $(this).data("video-id");
            var data = { video_id: video_id }
            $.ajax({
                url: '/api/comment/unpin_comment',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data:  JSON.stringify(data),
                success: function() {
                    // console.log(data)
                }
            });
        });

        $("#admin_panel_messages").on('click', '#archive_comment', function (e) {
            e.preventDefault();
            var video_id =  $(this).data("video-id");
            var comment_id =  $(this).data("comment-id");
            var data = { video_id: video_id, comment_id: comment_id }
            $.ajax({
                url: '/api/comment/archive_comment',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                data:  JSON.stringify(data),
                success: function() {
                    // console.log(data)
                }
            });
        });
    }
})
